import React, { useCallback } from 'react'
import SPage, { SPageType } from 'components/opinionless/SPage'
import { useTranslation, Trans } from '../../i18n'

import SText from 'components/opinionless/SText'
import SButton from 'components/opinionless/SButton'
import { useRouter } from 'next/dist/client/router'

interface Props {}

const Container404: React.FC<Props> = (props: Props) => {
    const router = useRouter()

    const onClickNeedHelp = useCallback(() => {
        window.Intercom('show')
    }, [])

    const onClickReturnHome = useCallback(() => {
        router.replace('/')
    }, [router])

    return (
        <SPage pageType={SPageType.NotFound}>
            <SText mb={7} color="gray400" maxWidth={500}></SText>
        </SPage>
    )
}

export default Container404
